import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Footer from "../Footers/Footer";
import Header from "../Navbar/Header";
import axios from "axios";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";
import { FormControl, Form } from "react-bootstrap";

const MemberDetails = () => {
  //loder
  const { promiseInProgress } = usePromiseTracker();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 640);

  const updateView = () => {
    setIsDesktop(window.innerWidth > 640);
  };

  useEffect(() => {
    // Update the view on component mount
    updateView();
    // Add event listener for window resize
    window.addEventListener("resize", updateView);
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", updateView);
  }, []);

  // tabcode
  const [planlistdata, setPlanListdata] = useState([])
  const [headtabledata, setHeadTableData] = useState({})
  const [activeTab, setActiveTab] = useState("Free")

  const handleSelect = (value) => {
    setActiveTab(value);
  };
  useEffect(() => {
    trackPromise(
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/planslist`)
        .then((response) => {
          if (response.status == 200) {
            setPlanListdata(response.data.plans)
            setHeadTableData(response.data.plans[0])
          }
        })
        .catch((error) => {
          console.log(error);
        })
    )
  }, [])


  return (
    <>
      {isDesktop ? (
        <>
          <Header />
          <div className="plan-new web-view">
            <section className="pt-2 pb-3">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <h6 className="benift-text">
                      Benefits of a membership plan :
                    </h6>
                    {promiseInProgress ? (
                      <div className="spinner spinner-box">
                        <ThreeDots
                          height="80"
                          width="80"
                          color="#ac216c"
                          ariaLabel="circles-loading"
                          visible={true}
                        />
                      </div>
                    ) : (
                      <table className="table-responsive table-borderless">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.key_value}
                            </th>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.value1}
                            </th>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.value2}
                            </th>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.value3}
                            </th>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.value4}
                            </th>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.value5}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {planlistdata
                            .slice(1)
                            .map((data) => {
                              return (
                                <>
                                  <tr>
                                    <td className="bg-white p-2  border">{data.key_value}</td>
                                    <td className="bg-white p-2  border">{data.value1}</td>
                                    <td className="bg-white p-2  border">{data.value2}</td>
                                    <td className="bg-white p-2  border">{data.value3}</td>
                                    <td className="bg-white p-2  border">{data.value4} </td>
                                    <td className="bg-white p-2  border">{data.value5}</td>
                                  </tr>
                                </>
                              )
                            })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <div className="plan-new mobile-view">
          <h6 className="benift-text">Benefits of a membership plan :</h6>
          <div className="container">
            <Form className="form-new mb-4">
              <FormControl as="select" className="form-control-select-box" onChange={(e) => handleSelect(e.target.value)}>
                <option value="Free" className="drop-down-tabselect">Free</option>
                <option value="Essential Access ( 1.99 )">Essential Access</option>
                <option value="Personal Perk ( 3.49 )">Persona Perk</option>
                <option value="Elite Ultimate ( 5.49 )">Elite Ultimate</option>
                <option value="Luxe Exclusive ( 9.49 )">Luxe Exclusive</option>
              </FormControl>
            </Form>
            <Tabs
              defaultActiveKey="Features"
              id="controlled-tab-example"
              // activeKey={key}
              // onSelect={(k) => setKey(k)}
              // className="mb-3"
              // onSelect={(eventKey) => setActiveTab(eventKey)}
              activeKey={activeTab}>

              <Tab eventKey="Free">
                <div className="members-details-mobile">
                  {promiseInProgress ? (
                    <div className="spinner spinner-box">
                      <ThreeDots
                        height="80"
                        width="80"
                        color="#ac216c"
                        ariaLabel="circles-loading"
                        visible={true}
                      />
                    </div>
                  ) : (
                    <>
                      <table className="table-responsive table-borderless">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.key_value}
                            </th>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.value1}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {planlistdata.slice(1).map((data1) => {
                            return (
                              <>
                                <tr>
                                  <td className="bg-white p-2  border">{data1.key_value}</td>
                                  <td className="bg-white p-2  border">{data1.value1}</td>
                                </tr>
                              </>
                            )
                          })}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </Tab>

              <Tab eventKey="Essential Access ( 1.99 )">
                <div className="members-details-mobile">
                  {promiseInProgress ? (
                    <div className="spinner spinner-box">
                      <ThreeDots
                        height="80"
                        width="80"
                        color="#ac216c"
                        ariaLabel="circles-loading"
                        visible={true}
                      />
                    </div>
                  ) : (
                    <>
                      <table className="table-responsive table-borderless">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.key_value}
                            </th>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.value2}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {planlistdata.slice(1).map((data1) => {
                            return (
                              <>
                                <tr>
                                  <td className="bg-white p-2  border">{data1.key_value}</td>
                                  <td className="bg-white p-2  border">{data1.value2}</td>
                                </tr>
                              </>
                            )
                          })}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </Tab>

              <Tab eventKey="Personal Perk ( 3.49 )">
                <div className="members-details-mobile">
                  {promiseInProgress ? (
                    <div className="spinner spinner-box">
                      <ThreeDots
                        height="80"
                        width="80"
                        color="#ac216c"
                        ariaLabel="circles-loading"
                        visible={true}
                      />
                    </div>
                  ) : (
                    <>
                      <table className="table-responsive table-borderless">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.key_value}
                            </th>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.value3}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {planlistdata.slice(1).map((data1) => {
                            return (
                              <>
                                <tr>
                                  <td className="bg-white p-2  border">{data1.key_value}</td>
                                  <td className="bg-white p-2  border">{data1.value3}</td>
                                </tr>
                              </>
                            )
                          })}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </Tab>

              <Tab eventKey="Elite Ultimate ( 5.49 )">
                <div className="members-details-mobile">
                  {promiseInProgress ? (
                    <div className="spinner spinner-box">
                      <ThreeDots
                        height="80"
                        width="80"
                        color="#ac216c"
                        ariaLabel="circles-loading"
                        visible={true}
                      />
                    </div>
                  ) : (
                    <>
                      <table className="table-responsive table-borderless">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.key_value}
                            </th>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.value4}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {planlistdata.slice(1).map((data1) => {
                            return (
                              <>
                                <tr>
                                  <td className="bg-white p-2  border">{data1.key_value}</td>
                                  <td className="bg-white p-2  border">{data1.value4}</td>
                                </tr>
                              </>
                            )
                          })}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </Tab>

              <Tab eventKey="Luxe Exclusive ( 9.49 )">
                <div className="members-details-mobile">
                  {promiseInProgress ? (
                    <div className="spinner spinner-box">
                      <ThreeDots
                        height="80"
                        width="80"
                        color="#ac216c"
                        ariaLabel="circles-loading"
                        visible={true}
                      />
                    </div>
                  ) : (
                    <>
                      <table className="table-responsive table-borderless">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.key_value}
                            </th>
                            <th
                              scope="col"
                              className="gold-premum-plas text-white p-2  border">
                              {headtabledata.value5}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {planlistdata.slice(1).map((data1) => {
                            return (
                              <>
                                <tr>
                                  <td className="bg-white p-2  border">{data1.key_value}</td>
                                  <td className="bg-white p-2  border">{data1.value5}</td>
                                </tr>
                              </>
                            )
                          })}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
};
export default MemberDetails;





